<template>
  <BaseDialog
    :max-width="400"
    :propDialog="dialog"
    :title="title"
    :validity="valid"
    :loader="loading"
    @confirm="submitOption"
    @closeDialog="setDialog"
  >
    <p class="text-h6 text-capitalize primary--text">
      {{
        $i18n.locale === 'vi' || !option.enName ? option.viName : option.enName
      }}
    </p>
    <v-form ref="form" v-model="valid" @submit.prevent
      ><BaseCurrencyInput
        outlined
        label="dish.price"
        v-model="selectedOption.price"
        required
        currency="VND"
    /></v-form>
    <BaseEntry
      v-if="runCampaign"
      label="dish.price-modifier"
      :content="priceModifierString"
    />
    <BaseEntry
      v-if="runCampaign"
      label="dish.final-price"
      :content="finalPrice"
      contentColor="primary--text"
    />
  </BaseDialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { displayErrorMessage, getPriceModifier } from '@/utils'
import { mapState } from 'vuex'

export default {
  name: 'DishOptionPrice',

  props: {
    dish: Object,
    option: Object,
    dialog: Boolean,
    update: Boolean
  },

  data: () => ({
    title: '',
    selectedOption: {},
    valid: true,
    loading: false
  }),

  computed: {
    ...mapState('common', ['runCampaign', 'campaignDetails']),

    priceModifier() {
      return getPriceModifier(this.selectedOption)
    },

    priceModifierString() {
      const modifier = this.priceModifier
      const displayString = modifier.toLocaleString()
      if (modifier > 0) {
        return `+${displayString} VND`
      } else {
        return `${displayString} VND`
      }
    },

    finalPrice() {
      const { price } = this.selectedOption
      return `${(price + this.priceModifier).toLocaleString()} VND`
    }
  },

  watch: {
    option: {
      immediate: true,
      handler: function(val) {
        this.selectedOption = cloneDeep(val)
        if (this.update) {
          const initModifier = this.selectedOption.priceModifier
          this.selectedOption.price -= initModifier
          this.title = 'dish.update-option'
        } else {
          this.title = 'dish.add-option'
        }
      }
    }
  },

  methods: {
    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    submitOption() {
      this.loading = true

      const submit = () => {
        if (!this.update) {
          return this.$store.dispatch('dish/addOption', {
            dishId: this.dish._id,
            option: this.selectedOption
          })
        } else {
          return this.$store.dispatch('dish/updateOption', {
            optionId: this.selectedOption._id,
            option: this.selectedOption
          })
        }
      }

      submit()
        .then(() => {
          this.setDialog(false)
          this.$emit('updateOptionList')
        })
        .catch(err => displayErrorMessage(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style scoped></style>
